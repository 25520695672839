import {NgModule} from '@angular/core'
import {BrowserModule} from '@angular/platform-browser'
import {AppRoutingModule} from './app-routing.module'
import {AppComponent} from './app.component'
import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {AuthInterceptor} from './core/interceptors'
import {AppSideNavComponent, LoginComponent} from "./components"
import {TopNavComponent} from "./core/components/top-nav/top-nav.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {ToastrModule} from "ngx-toastr";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {DotAnimationComponent} from "./core/components/loading/dot-animation/dot-animation.component";
import {NotificationAlertComponent} from "./core/components/notification/notification-alert.component";

@NgModule({ declarations: [
        AppComponent,

    ],
    bootstrap: [AppComponent],
    exports: [
        NotificationAlertComponent,
    ], imports: [BrowserModule,
        AppRoutingModule,
        FontAwesomeModule,
        AppSideNavComponent,
        TopNavComponent,
        BrowserAnimationsModule,
        ToastrModule.forRoot(),
        SweetAlert2Module.forRoot(),
        LoginComponent,

        NotificationAlertComponent,
        NotificationAlertComponent, DotAnimationComponent], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true,
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
}
