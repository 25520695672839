import {Component, OnInit} from '@angular/core';
import {Router, RouterLink} from "@angular/router";
import {AuthService} from "../../services";
import {NgForOf, NgIf} from "@angular/common";
import {NavItem} from "./nav-item.entity";
import {Permission} from "../../types";

@Component({
    selector: 'app-top-nav',
    standalone: true,
    imports: [
        RouterLink,
        NgIf,
        NgForOf
    ],
    templateUrl: './top-nav.component.html',
    styleUrl: './top-nav.component.scss'
})
export class TopNavComponent implements OnInit{
    constructor(private router: Router, private authService: AuthService) {}

    navItems: NavItem[] = [
        { path: 'sales-order', label: 'Sales Order', canAccess: false },
        { path: 'sales-order-item', label: 'Sales Order Item', canAccess: false },
        { path: 'production-update', label: 'Production Quantity', canAccess: false },
        { path: 'production-planing', label: 'Production Planning', canAccess: false }
    ];

    ngOnInit() {
        this.setNavigationPermissions();
    }

    private setNavigationPermissions() {
        const userAuthData = sessionStorage.getItem('user-auth');
        if (userAuthData) {
            try {
                const userData = JSON.parse(userAuthData);
                const permissions: Permission[] = userData.permissions || [];

                this.navItems = this.navItems.map(item => ({
                    ...item,
                    canAccess: permissions.some(p => p.path === item.path && p.canAccess)
                }));
            } catch (error) {
                console.error('Error parsing permissions:', error);
            }
        }
    }

    isActive(route: string): boolean {
        return this.router.url === '/' + route || this.router.url.startsWith('/' + route + '/');
    }

    logOut() {
        this.authService.userSignOut();
    }
}