import {NgModule} from '@angular/core'
import {RouterModule, Routes} from '@angular/router'
import {AuthGuard} from "./core";

const routes: Routes = [
  { path: '', redirectTo: 'sales-order', pathMatch: 'full' },
  {
    path: 'login',
    loadComponent: () => import('./core/index').then(com => com.LoginComponent),
  },
  {
    path: 'sales-order',
    loadComponent: () => import('./components/index').then(com => com.SalesOrderComponent),
    canActivate:[AuthGuard]
  },
  {
    path: 'sales-order-item',
    loadComponent: () => import('./components/index').then(com => com.SalesOrderItemComponent),
    canActivate:[AuthGuard]
  },
  {
    path:'production-update',
    loadComponent:() => import('./components/index').then(com => com.ProductionUpdateComponent),
    canActivate:[AuthGuard]
  }, {
    path:'production-planing',
    loadComponent:() => import('./components/index').then(com => com.ProductionPlanningComponent),
    canActivate:[AuthGuard]
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
