<nav class="bg-white shadow-md">
    <div class="w-full px-4 sm:px-6 lg:px-8">
        <div class="flex items-center justify-between h-16">
            <div class="flex-shrink-0 "></div>
            <div class="flex justify-center flex-grow">
                <ng-container *ngFor="let item of navItems">
                    <button
                            *ngIf="item.canAccess"
                            [routerLink]="item.path"
                            [class.active]="isActive(item.path)"
                            class="nav-button mx-2">
                        {{ item.label }}
                    </button>
                </ng-container>
            </div>
            <div class="mr-6">
                <button  (click)="logOut()"
                        class="log-button flex items-center">
                    Logout
                </button>
            </div>
        </div>
    </div>
</nav>