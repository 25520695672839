<div class="fixed top-6 z-50 animate-fade-down w-full sm:w-auto sm:right-40 flex justify-center sm:justify-end">
    <div [id]="'toast-' + type"
         class="flex items-center width-full p-4 mb-4 text-gray-500 rounded-2xl shadow-2xl" role="alert"
         [ngClass]="{
       ' bg-green-50 ': type === 'success',
       'bg-red-50 ': type === 'error',
       'bg-orange-50 ': type === 'alert',
       'bg-blue-50': type === 'confirm'
      }">
        <div class="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 rounded-lg"
             [ngClass]="{
         'text-green-500 bg-green-200 ': type === 'success',
         'text-red-500 bg-red-200 ': type === 'error',
         'text-orange-500 bg-orange-200 ': type === 'alert',
         'text-blue-500 bg-blue-200': type === 'confirm'
       }">
            <svg class="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor"
                 viewBox="0 0 20 20">
                @if (type === 'success') {
                    <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 8.207-4 4a1 1 0 0 1-1.414 0l-2-2a1 1 0 0 1 1.414-1.414L9 10.586l3.293-3.293a1 1 0 0 1 1.414 1.414Z"/>
                } @else if (type === 'error') {
                    <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z"/>
                } @else if (type === 'alert') {
                    <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z"/>
                } @else if (type === 'confirm') {
                    <path
                            d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9 13a1 1 0 1 1 2 0 1 1 0 0 1-2 0Zm1-8a1 1 0 0 1 1 1v4a1 1 0 1 1-2 0V6a1 1 0 0 1 1-1Z"/>
                }
            </svg>
            <span class="sr-only">
      {{ type === 'success' ? 'Success' : type === 'error' ? 'Error' : type === 'alert' ? 'Warning' : 'Confirmation' }}
                icon
    </span>
        </div>
        <div class="ms-3 text-sm font-normal pr-4">{{ message }}</div>
    </div>
</div>
